<template>
    <div class="netto-price" v-show="isClientSide">
        <div class="netto-price__wrapper">
            <div v-if="listPrice" class="text-right list-price">
                {{ listPriceFormatted }}
            </div>
            <!--extra span wrapper to separate price from slot (usually discount badge). Makes possible to align from and per person as baseline-->
            <div v-if="!showFreeOfCharge" class="price-text">
                <span v-if="!hideHelp" class="help-text">
                    <span v-if="minNightPrice" :class="perNightCSS" :title="$t('Price.perPersonPerNight')">p.N.</span>
                    <span v-if="minPersonPrice" :class="perPersonCSS" :title="$t('Price.perPerson')"> p.P. </span>
                </span>
                <span v-if="showPrefix" class="prefix">{{ prefix }}</span>
            </div>

            <div
                v-if="showFreeOfCharge"
                class="price-value"
                :class="{ [priceModes.package.cssClass]: true, 'd-block': ignorePriceMode }"
            >
                gratis
            </div>
            <!--Sometimes the price can be undefined, ignore price mode set to true-->
            <div
                v-else-if="minPrice"
                class="price-value"
                :class="{ [priceModes.package.cssClass]: true, 'd-block': ignorePriceMode }"
            >
                {{ minPrice }}
            </div>
            <div v-if="minPersonPrice" class="price-value" :class="perPersonCSS">
                {{ minPersonPrice }}
            </div>
            <div v-if="minNightPrice" class="price-value" :class="perNightCSS">
                {{ minNightPrice }}
            </div>
        </div>

        <slot></slot>
    </div>
</template>

<script>
import Price from '@/components/Price';
import formatPriceFilter from '@/filters/formatPrice';

export default {
    name: 'NettoPrice',
    extends: Price,
    props: {
        listPrice: Number
    },
    computed: {
        listPriceFormatted() {
            return this.listPrice && formatPriceFilter(this.listPrice, true);
        },
        showFreeOfCharge() {
            return this.minimumPrice === 0 && this.$store.state.ident === 'nettovorteilswelt';
        }
    }
};
</script>

<style scoped lang="scss">
@use '../styles/variables' as theme;

.netto-price {
    font-family: 'FrankfurterNetto', sans-serif;
    min-width: 90px;
    text-align: center;
    display: inline-block;
    position: relative;

    .price-text {
        display: block;
        background: theme.$secondary;
        color: theme.$primary;
        line-height: 23px;
        font-size: 13px;

        .help-text {
            cursor: help;
        }

        .prefix {
            text-transform: lowercase;
        }
    }

    .price-value {
        background: theme.$primary;
        color: hsl(0, 0%, 100%);
        font-weight: bold;
        line-height: 32px;
    }

    .list-price {
        color: theme.$body-color;
        position: absolute;
        top: -1.5em;
        right: 0;

        &::before {
            content: '';
            background: linear-gradient(90deg, rgba(theme.$primary, 0.75) 50%, rgba(220, 13, 21, 0));
            width: 100%;
            height: 3px;
            display: inline-block;
            position: absolute;
            bottom: 13px;
            left: 0;
            border-radius: 10px;
            transform: rotate(-20deg);
        }
    }
}
.auction-price {
    .price-text {
        .prefix {
            text-transform: none;
        }
    }
}
</style>
