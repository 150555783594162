<template>
    <div class="kiu-grid-tile" :class="{ 'kiu-grid-tile--sold-out': block.soldOut }">
        <template v-if="block.type !== 'content'">
            <Link :link="block.link" class="kiu-grid-tile__image-link">
                <ImageFluid
                    :src="block.imageUrl"
                    :alt="block.text"
                    imgClass="kiu-grid-tile__media-image kiu-grid-tile__media-image--product"
                    :eager="highest"
                    width="350"
                    height="260"
                />

                <div v-if="banner" class="kiu-grid-tile__banner" :style="bannerStyle">
                    {{ banner }}
                </div>

                <span v-if="block.soldOut" class="kiu-grid-tile__product-middle btn btn-secondary">
                    {{ $t('ProductDetails.productSelect.soldOut') }}
                </span>
                <CountdownTimer
                    v-else-if="block.availableUntil"
                    :available-until="block.availableUntil"
                    class="kiu-grid-tile__product-middle kiu-grid-tile__countdown"
                    style="width: 184px"
                />
            </Link>

            <div class="kiu-grid-tile__details">
                <Link :link="block.link" class="kiu-grid-tile__title">{{ title }}</Link>

                <p class="kiu-grid-tile__subtitle">
                    {{ block.extraTileText || block.text }}
                </p>

                <div class="kiu-grid-tile__footer">
                    <Price
                        v-if="productPrice"
                        :price="block.price"
                        small-prefix
                        show-prefix
                        class="kiu-grid-tile__price"
                        preferred-mode="minimumPrice"
                    />

                    <Link :link="block.link" class="kiu-grid-tile__bottom-link btn"> Details</Link>
                </div>
            </div>
        </template>

        <template v-else>
            <ImageFluid
                :src="block.imageUrl"
                :alt="block.text"
                imgClass="kiu-grid-tile__media-image kiu-grid-tile__media-image--dimmed"
                class="kiu-grid-tile__media-picture--dimmed"
                :eager="highest"
                width="393"
                height="430"
            />

            <div class="kiu-grid-tile__content-middle">
                <header class="kiu-grid-tile__content-title">{{ block.text }}</header>

                <Link :link="block.link" class="kiu-grid-tile__content-button btn btn-secondary">Anzeigen</Link>
            </div>
        </template>
    </div>
</template>

<script>
import GridTile from '@/components/grid/GridTile.vue';

export default {
    name: 'KIUGridTile',
    extends: GridTile
};
</script>

<style scoped lang="scss">
.kiu-grid-tile {
    background-color: hsl(205, 85%, 95%);
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-bottom: 0;
    min-height: 360px;

    $self: &;

    &:hover {
        ::v-deep #{$self}__media-image {
            transform: scale(1.075);
        }
    }

    &__image-link {
        flex: 0 0 auto;
        aspect-ratio: 100 / 66;
        overflow: hidden;
        position: relative;
    }

    &__banner {
        position: absolute;
        top: 1rem;
        left: 1rem;
        z-index: 1;
        background-color: var(--primary);
        color: var(--white);
        padding: 0.375rem 0.75rem;
    }

    &__product-middle {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 50%;
        text-align: center;
        z-index: 1;
        font-weight: bold;
    }

    &__countdown {
        width: 184px;

        ::v-deep {
            .countdown-timer__item {
                background-color: var(--secondary);
                border-radius: 0;
                color: var(--white);
            }
        }
    }

    ::v-deep &__media-image {
        transition: transform 0.6s ease !important;
    }

    &__media-picture--dimmed {
        &::after {
            content: '';
            display: block;
            inset: 0;
            position: absolute;
            background-color: hsla(206, 100%, 86%, 0.41);
            z-index: 1;
        }
    }

    ::v-deep &__media-image--dimmed,
    &--sold-out ::v-deep #{$self}__media-image {
        filter: contrast(0.5) brightness(150%) grayscale(1) opacity(0.7);
    }

    &__details {
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        flex: 1 0 auto;
    }

    &__title {
        color: hsl(205, 51%, 47%);
        font-weight: bold;
        font-size: clamp(1rem, 0.9982rem + 0.1585vw, 1.125rem);

        &:hover {
            text-decoration: none;
        }
    }

    &__subtitle {
        margin-bottom: 0;
        font-size: 0.875rem;
        line-height: 1.3em;
    }

    &__footer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-top: auto;
    }

    &__price {
        color: var(--secondary);
        font-size: clamp(1.25rem, 1.0833rem + 0.8333vw, 1.75rem) !important;

        ::v-deep {
            .help-text {
                color: var(--body-color);
                font-size: 0.875rem !important;
                font-weight: 400;
            }

            .prefix.small {
                text-transform: lowercase;
            }
        }
    }

    &__content-middle {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 5%;
        width: 90%;
        text-align: center;
        overflow: hidden;
        z-index: 1;
    }

    &__content-title {
        font-size: clamp(1.25rem, 1.25rem + 0.7813vw, 1.875rem);
        font-weight: bold;
        line-height: 1.2;
    }

    &__content-button {
        margin: 0 auto;
        margin-top: 10px;
        color: hsl(0, 0%, 100%);
        font-size: 1.125rem;
        background-color: var(--secondary);
        padding: 10px 40px;
        font-weight: bold;

        &:hover {
            text-decoration: none;
            color: hsl(0, 0%, 100%);
        }
    }

    &__bottom-link {
        background-color: hsl(205, 51%, 47%);
        color: hsl(0, 0%, 100%);
        margin-left: auto;

        &:hover {
            background-color: hsl(205, 51%, 37%);
        }
    }
}
</style>
