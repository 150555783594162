<template>
    <Link class="product-block" :link="block.link || {}" :type="block.type">
        <div class="product-block__media netto-block__media">
            <ImageLazy
                :src="block.imageUrl"
                :alt="block.text"
                class="product-block__media-image"
                :force-load="highest"
            />

            <div v-if="block.directBookingAvailable || banner" class="product-block__media-top">
                <div v-if="banner" class="product-block__media-banner">
                    {{ banner }}
                </div>

                <div
                    v-if="block.directBookingAvailable"
                    class="product-block__media-banner"
                    title="Direkt Buchung möglich"
                >
                    <IconSvg filepath="calendar.svg" class="product-block__direct-booking-icon" />
                </div>
            </div>

            <div v-if="block.soldOut" class="product-block__media-soldout">
                <div class="product-block__media-soldout-text">
                    {{ $t('ProductDetails.productSelect.soldOut') }}
                </div>
            </div>
        </div>

        <div v-if="hasBottomText" class="product-block__bottom netto-block">
            <div
                v-skeleton="block.text"
                class="product-block__title netto-block__title"
                :class="{
                    'product-block__title--content-block': isContentBlock,
                    'netto-block__title--no-text': !title
                }"
                :title="block.text"
            >
                <span class="product-block__title-clamp">
                    {{ block.text }}
                </span>
            </div>

            <div
                class="product-block__text netto-block__text"
                :class="{ 'pr-4': productPrice && productPrice.minimumPrice.listPrice }"
            >
                <span v-if="title" v-skeleton="title" class="product-block__text-truncate text-truncate" :title="title">
                    {{ title }}
                </span>
            </div>

            <div class="netto-block__footer">
                <Favorite v-if="showFavorite" :product="block" class="netto-block__favorite">
                    <p v-if="isEdekaSuedwestIdent" class="netto-block__favorite-text">Merken</p>
                    <p v-else class="netto-block__favorite-text">Angebot merken</p>
                </Favorite>

                <div v-if="block.availableUntil">
                    <CountdownTimer
                        :available-until="block.availableUntil"
                        show-heading
                        @expired="isExpired = true"
                        class="text-center mb-3"
                    />
                </div>

                <NettoPrice
                    v-else-if="productPrice && !block.soldOut"
                    class="product-block__price netto-block__price"
                    :class="{ 'netto-block__price--discount': showDiscount }"
                    :minimumPrice="productPrice.minimumPrice.price"
                    :list-price="productPrice.minimumPrice.listPrice"
                    ignore-price-mode
                    :prefix="isValueVoucher ? 'Nur' : undefined"
                >
                    <DiscountBadge v-if="showDiscount" :discount="discountPercentage" class="netto-block__discount" />
                </NettoPrice>

                <button
                    v-if="!isContentBlock"
                    class="product-block__offer-button netto-block__offer-button btn btn-outline-primary mx-auto btn-block"
                    :class="{ 'btn-primary': isEdekaSuedwestIdent }"
                    type="button"
                >
                    <span v-if="isEdekaSuedwestIdent && hasMultipleOffers">Zu den Angeboten</span>
                    <span v-else>Zum Angebot</span>
                </button>
            </div>
        </div>
    </Link>
</template>

<script>
import ImageLazy from '@/components/ImageLazy';
import skeleton from '@/directives/skeleton';
import NettoPrice from '@/themes/nettovorteilswelt/components/NettoPrice';
import Favorite from '@/components/grid/Favorite';
import DiscountBadge from '@/components/DiscountBadge';
import CountdownTimer from '@/components/product/countdown/CountdownTimer.vue';

export default {
    name: 'PlainGridItem',
    directives: {
        skeleton
    },
    components: {
        CountdownTimer,
        DiscountBadge,
        Favorite,
        ImageLazy,
        NettoPrice
    },
    data() {
        return {
            isExpired: false
        };
    },
    props: {
        block: {
            type: Object,
            required: true
        },
        highest: Boolean
    },
    computed: {
        banner() {
            return this.block.bannerText || (this.block.newProduct && this.$t('Generic.newProduct'));
        },
        productPrice() {
            return this.block.price && this.block.price.regularPriceValues;
        },
        isValueVoucher() {
            return this.block.type === 'voucher' && this.block.productCategory === 'valuevoucher';
        },
        title() {
            return this.isValueVoucher ? 'Wertgutschein' : this.block.hotelName;
        },
        isNettoDiscountIdent() {
            return this.$store.state.ident === 'netto-discount';
        },
        isEdekaSuedwestIdent() {
            return this.$store.state.ident === 'edeka-suedwest';
        },
        showFavorite() {
            return (this.isNettoDiscountIdent || this.isEdekaSuedwestIdent) && !this.isContentBlock;
        },
        showDiscount() {
            return (
                this.isNettoDiscountIdent && this.$store.state.globalMeta.discountVisible && !!this.discountPercentage
            );
        },
        discountPercentage() {
            return this.productPrice.minimumPrice.discountPercentage;
        },
        isContentBlock() {
            return this.block.type === 'content';
        },
        hasBottomText() {
            return !this.isContentBlock || !!this.block.text;
        },
        hasMultipleOffers() {
            return this.block.availableVariants > 1 || this.directBookingAvailable;
        }
    }
};
</script>

<style scoped lang="scss">
@use 'sass:map';
@use '@/styles/variables';
@use '@/styles/theming' as *;
@use '@/styles/edeka-group/mixins';

.product-block {
    $block-padding: 25px;
    box-shadow: none;
    justify-content: flex-end;
    display: flex;
    flex-direction: column;
    min-height: 450px;
    transition: all 0.2s ease-in-out;

    &__media {
        flex-grow: 1;
        border: 1px solid var(--primary);
        position: relative;
    }

    &__media-image {
        position: absolute;
        z-index: 1;
    }

    &__media-soldout {
        background-image: url('~assets/soldout.png');
        background-repeat: repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &__media-soldout-text {
        position: absolute;
        left: variables.$grid-padding;
        bottom: variables.$grid-padding;
        background-color: rgba(variables.$soldout-color, 0.75);
        font-weight: 600;
        width: 140px;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        color: hsl(0, 0%, 100%);
        padding: 12px 0;
    }

    &__media-top {
        top: 0;
        position: absolute;
        width: 100%;
        justify-content: space-between;
        z-index: 3;
        padding: 10px;
    }

    &__media-banner {
        background: var(--primary);
        font-size: 15px;
        line-height: 1;
        padding: 5px;
        text-transform: uppercase;
        text-align: center;
        white-space: normal; //to prevent from overlapping the right media element
        display: inline-block;
        color: white;
        @include mixins.elevation;
        margin-right: 10px;
    }

    &__bottom {
        border: 1px solid #e0e0e0;
        flex-shrink: 0;
        padding: $block-padding;
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 15px;
        color: var(--primary);

        &--content-block {
            margin-bottom: 0;
            font-size: 20px !important;
            display: flex;
            align-items: center;
            text-align: center;
            flex: 1 0 150px;

            &::after {
                content: none !important;
            }
        }
    }

    &__title-clamp {
        text-overflow: ellipsis;
        line-clamp: 2;
        line-height: 1.1;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        width: 100%;
        overflow: hidden;
        white-space: normal;
    }

    &__text-truncate {
        display: inline-block;
        max-width: 100%;
    }

    &__offer-button {
        max-width: calc(#{map-get(variables.$grid, 'tile-width-lg')} - 2 * #{$block-padding});
    }
    @include themify(netto-discount, nettovorteilswelt, marktkaufreisen, edeka-suedwest) {
        &__media {
            min-height: themed('$grid', 'tile-media-height');
        }

        &__bottom {
            flex-basis: themed('$grid', 'tile-bottom-height');
        }
    }

    &:hover {
        box-shadow: 0 0 8px rgb(100 100 100 / 30%);
    }
}
</style>
